import React, { useState } from "react";

const CoverArt = ({ coverArtUrls, albumCoverUrl, openAppModal, size = "small", css, prompt, selectedOption }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleOpenModal = () => {
    if (!openAppModal) return;
    const modalContent = (
      <>
        {coverArtUrls?.large && (
          <div className="large-album-art mt-3">
            <img
              src={coverArtUrls?.large}
              alt="Cover art" loading="lazy"
             />
          </div>
        )}

        {albumCoverUrl && (
          <div className="large-album-art">
            <img
              src={albumCoverUrl}
              alt="Album cover"
              loading="lazy"
            />
          </div>
        )}

        {prompt && (
          <div className="box prompt mt-2">
            <p>
              Imagery generated by DALL-E via a prompt (see below) which was generated by ChatGPT using metadata from the show. This image may change as better image generation tools become available.
            </p>
            <p className="has-text-weight-bold mt-3">{prompt}</p>
          </div>
        )}
      </>
    );
    openAppModal(modalContent);
  };

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  const selectedImage = selectedOption === "albumCover" ?  albumCoverUrl : coverArtUrls?.[size];

  return (
    <div
      className={`cover-art cover-art-modal-trigger ${isLoaded ? "" : "loading-shimmer"}`}
      onClick={handleOpenModal}
    >
      <img
        src={selectedImage}
        alt={selectedOption === "coverArt" ? "Cover art" : "Album cover"}
        className={`${css} cover-art-${size}`}
        onLoad={handleImageLoad}
        loading="lazy"
      />
    </div>
  );
};

export default CoverArt;
